import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Select, Space, Tooltip } from "antd";
import { Link } from "react-router-dom";
import PageHeader from "../../view/pageheader";
import TableComponent from "../CustomComponents/TableComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { resetSuccessFlag, deleteJob, getJobsList, updateJobStatus,getUsersJobsList } from "../../store/reducer/jobSlice";
import { useEffect } from "react";
import SwalComponent from "../CustomComponents/SwalComponent";
import Swal from "sweetalert2";
import SearchComponent from "../CustomComponents/SearchComponent";


const Jobs = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { jobListData, deleteJobSuccess,jobStatusSuccess,userJosData } = useSelector((state) => state.jobs);
   console.log(userJosData,"userJosData");
   
   const role_id = localStorage.getItem("role_id");
   const userId=localStorage.getItem("user_id");
   const { count: totalCount, data: tableData } = role_id==3?userJosData:jobListData;

 

   function getData(payload = {}) {
      if (role_id == 3) {
         // Add userId to payload for role_id == 3
         dispatch(getUsersJobsList({ ...payload, userId }));
       } else {
         dispatch(getJobsList(payload));
       }
   }
   useEffect(() => {
      getData({})
      if (deleteJobSuccess) {
        
      
         toast.success("Job Deleted Successfully");
         dispatch(resetSuccessFlag({ flag: "deleteJobSuccess" }));
         
      }

   }, [deleteJobSuccess,dispatch])

useEffect(()=>{
   if(jobStatusSuccess){
      toast.success("Job status updated successfully");
      getData();
      dispatch(resetSuccessFlag({ flag: "jobStatusSuccess" }))
   }

},[jobStatusSuccess])
const handleStatusChange = (record, updatedStatus) => {
   Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to change the Job status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!'
   }).then((result) => {
      if (result.isConfirmed) {
         dispatch(updateJobStatus({
            postData: { status: updatedStatus },
            updateID: record.id
         }));
        
      }
   });
}
  const columns = [
     
      {
         title: "Job Name",
         dataIndex: "job_name",
         key: "job_name",
         align: "center",
         sorter: true,

      },
      {
         title: "Customer",
         dataIndex: 'customer',
         key: "customer",
         align: "center",
         sorter: true,
         render: data => (
            data?.customername ? data?.customername : "N/A"
         )
      },
      {
         title: "To",
         dataIndex: "user_role",
         key: "to",
         align: "center",
         sorter: true,
         render: data => (
            data?.user_name ? data?.user_name : "N/A"
            
            
         )
      },
      {
         title: "Pickup Date",
         dataIndex: "pickup_date",
         key: "created_by",
         align: "center",
         sorter: true,
         render: (date) => {
            const formattedDate = new Date(date).toLocaleDateString("en-GB"); // Formats date as dd/mm/yyyy
            return formattedDate;
         }
      },
      {
         title: "Spec ID",
         dataIndex: "spec_id",
         key: "spec_id",
         align: "center",
         sorter: true,
      },
      
      {
         title: "Status",
         key: "status",
         align: "center",
         render: (record) => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: record.status === 1 ? 'blue' : 'inherit',  }}>
                <Select
                  value={record.status}
                  onChange={(value) => handleStatusChange(record, value)}
                 
               >
                  <Select.Option value={1} style={{ color: 'blue' }}>Awaiting Review</Select.Option>
                  <Select.Option value={2} style={{ color: 'orange' }}>Yet to Start</Select.Option>
                  <Select.Option value={3} style={{ color: 'green' }}>In Progress</Select.Option>
                  <Select.Option value={4} style={{ color: 'gray' }}>Completed</Select.Option>
                  <Select.Option value={5} style={{ color: 'red' }}>Return to Customer</Select.Option>
               </Select>
            </div>
            
            
         )
      },
      {
         title: role_id == 1 || role_id == 2 ? "Action" : "",
         key: "Action",
         align: "center",
         render: data => {
           if (role_id == 1 || role_id == 2) {
             return (
               <Space>
                 <Tooltip title="Edit">
                   <Button
                     shape="circle"
                     className="border-0"
                     icon={
                       <EditOutlined
                         onClick={() => navigate(`/jobs/edit/${btoa(data?.id)}`)}
                         style={{ color: "blue" }}
                       />
                     }
                   />
                 </Tooltip>
                 <Tooltip title="Delete">
                   <SwalComponent
                     message="Are you sure you want to delete the Job?"
                     onClickDispatch={deleteJob}
                     id={data?.id}
                   />
                 </Tooltip>
               </Space>
             );
           }
           return null; // Return null if the condition is not met
         }
       }
       
   ];
   const UserColumns = [
     
      {
         title: "Job Name",
         dataIndex: "job_name",
         key: "job_name",
         align: "center",
         sorter: true,

      },
      
     
      {
         title: "Pickup Date",
         dataIndex: "pickup_date",
         key: "created_by",
         align: "center",
         sorter: true,
         render: (date) => {
            const formattedDate = new Date(date).toLocaleDateString("en-GB"); // Formats date as dd/mm/yyyy
            return formattedDate;
         }
      },
      {
         title: "Spec ID",
         dataIndex: "spec_id",
         key: "spec_id",
         align: "center",
         sorter: true,
      },
      
      {
         title: "Status",
         key: "status",
         align: "center",
         width: 150, // Set a fixed width for the column
         render: (record) => (
           <div
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               width: '100%', // Ensures the div stays within the column width
             }}
           >
             <Select
               value={record.status}
               onChange={(value) => handleStatusChange(record, value)}
               style={{ width: "150px" }} // Fixed width for the Select component
             >
               <Select.Option value={1} style={{ color: 'blue' }}>
                 Awaiting Review
               </Select.Option>
               <Select.Option value={2} style={{ color: 'orange' }}>
                 Yet to Start
               </Select.Option>
               <Select.Option value={3} style={{ color: 'green' }}>
                 In Progress
               </Select.Option>
               <Select.Option value={4} style={{ color: 'gray' }}>
                 Completed
               </Select.Option>
               <Select.Option value={5} style={{ color: 'red' }}>
                 Return to Customer
               </Select.Option>
             </Select>
           </div>
         ),
       },
       
     
       
   ];
   return (
      <div>
         <PageHeader HeaderText="Jobs" Breadcrumb={[{ name: "Jobs" }]} backButtonUrl="" />
         <Card
            title="Jobs List"
            extra={
               <>
             <Space size={"middle"} className="my-3">

<SearchComponent
   placeHolder="Search Job Name"
   onChange={e => {
      const payloadData = {
         query: e.target.value,
      };
      getData(payloadData);
   }} />


</Space>
               <Link to="/jobs/add">
                  <Button className="primary-btn ml-2" shape="round">
                     Add
                  </Button>
               </Link>
               </>
            }
         >
            <TableComponent columns={role_id==3?UserColumns:columns} dataSource={tableData} size={"small"} />
         </Card>
      </div>
   );
};

export default Jobs;
