import { CloseOutlined, DeleteOutlined, EditOutlined, FileDoneOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Modal, Row, Space, Tooltip } from "antd";
import { useState, useRef, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PageHeader from "../../view/pageheader";
import SelectInput from "../CustomComponents/SelectInput";
import TableComponent from "../CustomComponents/TableComponent";
import TextInput from "../CustomComponents/TextInput";
import { useDispatch, useSelector } from "react-redux";
import SwalComponent from "../CustomComponents/SwalComponent";
import {
   addCustomer,
   deleteCustomer,
   updateCustomer,
   getCustomerList,
   resetSuccessFlag,
   updateCustomerStatus
} from "../../store/reducer/customerSlice";
import { getUserManagementList } from "../../store/reducer/userManagementSlice";
import { toast } from "react-toastify";
import SearchComponent from "../CustomComponents/SearchComponent";
const Customers = () => {
   const [customerSchema] = useState({
      customername: "",
      sales_person_name: null,
      project_manager: null,
      status: 1,
   });
   const dispatch = useDispatch();
   const [type, setType] = useState('add')
   const [editId, setEditId] = useState(null);
   const [managerData, setManagerData] = useState([]);
   // Selectors
   const {
      customerListData,
      successFlag,
      addCustomerSuccess,
      updateCustomerSuccess,
      deleteCustomerSuccess,
      custometerStatusSuccess,
      addCustomerError,
      addCustomerErrorMessage
   } = useSelector((state) => state.customers);
   const {
      userManagementListData,

   } = useSelector((state) => state.userManagement);

   const { count: totalCount, data: tableData } = customerListData;
   function getDataProjectManager(payload = {}) {
      dispatch(getUserManagementList(payload));
   }
   useEffect(() => {
      getDataProjectManager();
   }, []);
   useEffect(() => {
      if (userManagementListData?.data) {

         setManagerData(userManagementListData?.data);
      }
   }, [userManagementListData]);
   function getData(payload = {}) {
      dispatch(getCustomerList(payload));
   }
  const managerList = useMemo(() => {
      return managerData.filter((item) => item.role_id === 2 && item.status === 1).map((item) => ({
         label: item.user_name,
         value: item.id
      }));
   }, [managerData]);

   const salesPersonList = useMemo(() => {
      return managerData.filter((item) => item.role_id === 4 && item.status === 1).map((item) => ({
         label: item.user_name,
         value: item.id
      }));
   }, [managerData])

   const [customerStatus] = useState([
      {
         label: "Active",
         value: 1,
      },
      {
         label: "Inactive",
         value: 0,
      },
   ]);

   const validationRules = yup.object().shape({
      customername: yup.string().trim().required("Customer name is required"),
      sales_person_name: yup.string().trim().required("Please select  sales person"),
      project_manager: yup
         .number()
         .typeError("Please select manager")
         .required("Project manager is required"),
      status: yup.number().typeError("Please select status").required("Status is required"),
   });

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm({
      resolver: yupResolver(validationRules),
      mode: "onChange", // Enable live validation
   });

   const [isAddModal, setIsAddModal] = useState(false);
   const inputAutoFocusRef = useRef(null);
   useEffect(() => {
      // Fetch the initial data for customers
      getData();

      // Handle success flags and their corresponding actions
      if (addCustomerSuccess) {
         toast.success("Customer added successfully");
         setIsAddModal(false); // Close the modal
         reset({ ...customerSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "addCustomerSuccess" })); // Reset the success flag
      }

      if (updateCustomerSuccess) {
         toast.success("Customer updated successfully");
         setIsAddModal(false); // Close the modal
         reset({ ...customerSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "updateCustomerSuccess" })); // Reset the success flag
      }
      if (addCustomerError) {
         toast.error(addCustomerErrorMessage);
         dispatch(resetSuccessFlag({ flag: "addCustomerError" })); // Reset the error flag

      }

      if (deleteCustomerSuccess) {
         toast.success("Customer deleted successfully");
         reset({ ...customerSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "deleteCustomerSuccess" })); // Reset the success flag
      }
      if (custometerStatusSuccess) {
         toast.success("Customer status updated successfully");
         reset({ ...customerSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "custometerStatusSuccess" })); // Reset the success flag

      }
      // Only re-run this effect when success flags change
   }, [addCustomerSuccess, updateCustomerSuccess,addCustomerErrorMessage,addCustomerError, deleteCustomerSuccess, dispatch, reset, customerSchema, custometerStatusSuccess]);

   useEffect(() => {
      if (isAddModal && inputAutoFocusRef.current) {
         setTimeout(() => {
            inputAutoFocusRef.current.focus();
         }, 300);
      }
   }, [isAddModal]);
   const columns = [
      {
         title: "Customer Name",
         dataIndex: "customername",
         key: "customername",
         align: "center",
         sorter: (a, b) => a.customername.localeCompare(b.customername),
      },
      {
         title: "Sales Person Name",
         dataIndex: "sales_person_name",
         key: "sales_person_name",
         align: "center",
         render: (sale_person_id) => {
            const group = salesPersonList.find((g) => g.value === sale_person_id);
            return group?.label || "No Group";
         },
         sorter: (a, b) => a.sales_person_name.localeCompare(b.sales_person_name),
      },
      {
         title: "Project Manager",
         dataIndex: "project_manager",
         key: "project_manager",
         render: (project_manager_id) => {
            const group = managerList.find((g) => g.value === project_manager_id);
            return group?.label || "No Group";
         },
         align: "center",
         sorter: (a, b) => a.project_manager - b.project_manager,
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: record => (
            <SwalComponent
               message="Are you sure you want to change the Customer status?"
               onClickDispatch={updateCustomerStatus}
               id={record.id}
               status={true}
               statusValue={record.status}
               postData={{ status: record.status == 1 ? 0 : 1 }}
            />
         ),
         sorter: (a, b) => a.status - b.status,
      },
      {
         title: "Action",
         key: "Action",
         align: "center",
         render: data => (
            <Space>
               <Tooltip title="Edit">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<EditOutlined style={{ color: "blue" }} />}
                     onClick={() => {
                        setType("edit");
                        setIsAddModal(true);
                        setEditId(data.id);
                        reset({
                           ...data,
                        });
                     }}
                  />
               </Tooltip>
               <Tooltip title="Delete">
                  <SwalComponent
                     message="Are you sure you want to delete the customer?"
                     onClickDispatch={deleteCustomer}
                     id={data?.id}
                  />
               </Tooltip>
            </Space>
         ),
      },
   ];

   const onSubmit = (data) => {
      if (type === "add") {
         const payload = {
            postData: data,
         };
         dispatch(addCustomer(payload)); // Dispatch add action
      } else if (type == "edit") {
         const payload = {
            postData: {
               customername: data?.customername, // Extract the specific fields for update
               sales_person_name: data?.sales_person_name,
               project_manager: data?.project_manager,
               status: data?.status,
            },
            updateID: editId, // Use the editId for identifying the record to update
         };
         dispatch(updateCustomer(payload)); // Dispatch update action
      }
   };

   const onModalClose = () => {
      reset();
      setIsAddModal(false);
   };
   const onModalOpen = () => {
      reset({ ...customerSchema });
      setIsAddModal(true);
   };
   return (
      <div>
         <PageHeader HeaderText="Customers" Breadcrumb={[{ name: "Customers" }]} backButtonUrl="" />
         <Card
            title="Customers List"
            extra={
               <>
                  <Space size={"middle"} className="my-3">
                     <SearchComponent

                        placeHolder="Search..."
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }} />
                  </Space>
                  <Button className="primary-btn ml-2" shape="round" onClick={() => {
                     setType("add");
                     onModalOpen(); // Open modal for Add
                  }}>
                     Add
                  </Button>
                  <Modal
                     title={type === "add" ? "Add Customer" : "Edit  Customer"}
                     centered
                     className="w-50"
                     open={isAddModal}
                     onOk={handleSubmit(onSubmit)}
                     onCancel={onModalClose}
                     okButtonProps={{
                        className: "primary-btn",
                        shape: "round",
                        icon: <FileDoneOutlined />,
                     }}
                     okText={type === "add" ? "Save" : "Update"}
                     cancelButtonProps={{ shape: "round", icon: <CloseOutlined /> }}
                  >
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={[10, 0]} wrap className="p-2">
                           <Col span={12}>
                              <TextInput
                                 ref={inputAutoFocusRef}
                                 name="customername"
                                 control={control}
                                 label="Name"
                                 placeholder="Enter customer name"
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           <Col span={12}>
                              <SelectInput
                                 name="sales_person_name"
                                 control={control}
                                 label="Sales Person Name"
                                 placeholder="Enter salesman name"
                                 options={salesPersonList}
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           <Col span={12}>
                              <SelectInput
                                 name="project_manager"
                                 control={control}
                                 label="Project Manager"
                                 placeholder="Select user role"
                                 options={managerList}
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           <Col span={12}>
                              <SelectInput
                                 name="status"
                                 control={control}
                                 label="Status"
                                 placeholder="Select Status"
                                 options={customerStatus}
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                        </Row>
                     </form>
                  </Modal>
               </>
            }
         >
            <TableComponent columns={columns} dataSource={tableData} size={"small"} />
         </Card>
      </div>
   );
};

export default Customers;
