import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, Modal, Row, Select, Space, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../view/pageheader";
import TableComponent from "../CustomComponents/TableComponent";
import { getWorkFlowList, resetSuccessFlag, updateWorkFlowStatus } from "../../store/reducer/workflowSlice";
import SearchComponent from "../CustomComponents/SearchComponent";
import SwalComponent from "../CustomComponents/SwalComponent";
import { toast } from "react-toastify";



const WorkFlow = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch();
   const { workflowListData,workflowStatusSuccess,workflowStatusError,workflowStatusErrorMessage } = useSelector((state) => state.workflow);
   const { count: totalCount, data: tableData } = workflowListData;
   useEffect(() => {
      getData();
   }, []);

   function getData(payload = {}) {
      dispatch(getWorkFlowList(payload));
   }
useEffect(()=>{
   if (workflowStatusSuccess) {
      toast.success("Workflow status updated successfully");
      getData();
      dispatch(resetSuccessFlag({ flag: "workflowStatusSuccess" }))
      
   }
   if (workflowStatusError) {
      toast.error(workflowStatusErrorMessage)
      dispatch(resetSuccessFlag({ flag: "workflowStatusError" }));
      
   }

},[workflowStatusSuccess,workflowStatusError,workflowStatusErrorMessage,dispatch])


   const [userStatus] = useState([
      {
         label: "Active",
         value: 1,
      },
      {
         label: "Inactive",
         value: 2,
      },
   ]);
   const columns = [
      {
         title: "Spec ID",
         dataIndex: "spec", // Adjusted to access nested value of spec.spec_id
         key: "spec_id",
         align: "center",
         render: (spec) => spec?.spec_id,
         sorter: (a, b) => a.spec.spec_id - b.spec.spec_id, // Sorting based on nested spec_id
      },
      {
         title: "Workflow Name",
         dataIndex: "workflow_name",
         key: "workflow_id",
         align: "center",
         sorter: true,
      },

      {
         title: "Status",
         key: "status",
         align: "center",
         render: record => (
           <SwalComponent
             message="Are you sure you want to change the Workflow status?"
             onClickDispatch={updateWorkFlowStatus}
             id={record.id}
             status={true}
             statusValue={record.status}
             postData={{ status: record.status == 1 ? 0 : 1 }}
           />
         ),
         sorter: (a, b) => a.status - b.status,
         
       },
      {
         title: "Action",
         key: "Action",
         align: "center",
         render: data => (
            <Space>
               <Tooltip title="Edit">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<EditOutlined
                        onClick={() => navigate(`/workflow/edit/${btoa(data?.id)}`)}
                        style={{ color: "blue" }} />}
                  />
               </Tooltip>
               <Tooltip title="Copy">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<CopyOutlined style={{ color: "green" }} />}
                     onClick={() => navigate(`/workflow/edit/${btoa(data?.id)}?type=copy`)}// Trigger modal with event data
                  />
               </Tooltip>
            </Space>
         ),
      },
   ];

   return (
      <div>
         <PageHeader HeaderText="Workflow" Breadcrumb={[{ name: "Workflow" }]} backButtonUrl="" />
         <Card
            title="Workflow List"
            extra={
               <>
                  <Space size={"middle"} className="my-3">
                     <SearchComponent
                        placeHolder="Search Workflow Name"
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }} />
                  </Space>
                  <Link to={'/workflow/add'}>
                     <Button className="primary-btn ml-2" shape="round" >
                        Add
                     </Button>
                  </Link>

               </>
            }
         >
            <Flex justify="start">
               <div className="w-75">
                  <TableComponent columns={columns} dataSource={tableData} size={"small"} />
               </div>
            </Flex>
         </Card>
      </div>
   );
};

export default WorkFlow;
