import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    createEvent,
    editEvent,
    eventList,
    eventListById,
    eventStatusUpdate
} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "event";

// Add Event
export const addEvent = createAsyncThunk(
    `${namespace}/addEvent`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createEvent({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Get Event List
export const getEventList = createAsyncThunk(
    `${namespace}/getEventList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", id = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await eventList(query, page, limit, sortby, order, id);
            return JSON.parse(EncryptDecrypt.decryptdata(data?.data?.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Get Event by ID
export const getEventById = createAsyncThunk(
    `${namespace}/getEventById`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await eventListById(id);
            return JSON.parse(EncryptDecrypt.decryptdata(data?.data?.datas, secretKey));
        } catch (error) {
            console.log("getEventById error--->", error.message);
            return rejectWithValue(error.response);
        }
    }
);

// Update Event
export const updateEvent = createAsyncThunk(
    `${namespace}/UpdateEvent`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editEvent({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Update Event Status
export const updateEventStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await eventStatusUpdate({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Slice
const eventSlice = createSlice({
    name: namespace,
    initialState: {
        eventListData: [],
        eventLoading: false,
        addEventSuccess: false,
        addEventError: false,
        addEventErrorMessage: "",
        eventListByIdData: [],
        eventListByIdError: false,
        updateEventSuccess: false,
        updateEventError: false,
        updateEventErrorMessage: "",
        eventStatusSuccess: false,
        eventStatusError: false,
        eventStatusErrorMessage: "",
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addEvent.pending, (state) => {
                state.eventLoading = true;
            })
            .addCase(addEvent.fulfilled, (state) => {
                state.eventLoading = false;
                state.addEventSuccess = true;
            })
            .addCase(addEvent.rejected, (state, action) => {
                state.addEventError = true;
                state.addEventErrorMessage = action.payload?.data?.message || "An error occurred";
            })
            .addCase(getEventList.pending, (state) => {
                state.eventLoading = true;
            })
            .addCase(getEventList.fulfilled, (state, action) => {
                state.addEventSuccess = false;
                state.updateEventSuccess = false;
                state.eventListData = action.payload;
                state.eventLoading = false;
            })
            .addCase(getEventList.rejected, (state) => {
                state.eventLoading = false;
            })
            .addCase(getEventById.pending, (state) => {
                state.eventLoading = true;
            })
            .addCase(getEventById.fulfilled, (state, action) => {
                console.log(action.payload,"payaaaaaaaaaaaaaaload");
                
                state.addEventError = false;
                state.eventLoading = false;
                state.eventListByIdData = action.payload;
            })
            .addCase(getEventById.rejected, (state) => {
                state.eventLoading = false;
            })
            .addCase(updateEvent.pending, (state) => {
                state.eventLoading = true;
            })
            .addCase(updateEvent.fulfilled, (state) => {
                state.eventLoading = false;
                state.updateEventSuccess = true;
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.updateEventError = true;
                state.updateEventErrorMessage = action.error.message || "An error occurred";
            })
            .addCase(updateEventStatus.pending, (state) => {
                state.eventLoading = true;
            })
            .addCase(updateEventStatus.fulfilled, (state) => {
                state.eventStatusSuccess = true;
                state.eventLoading = false;
            })
            .addCase(updateEventStatus.rejected, (state, action) => {
                state.eventStatusError = true;
                state.eventStatusErrorMessage = action.error.message || "An error occurred";
            });
    },
});

export const { resetSuccessFlag } = eventSlice.actions;
export const eventSelector = (state) => state[namespace];
export default eventSlice.reducer;
