import { EyeOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip, Modal, Flex } from "antd";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../view/pageheader";
import TableComponent from "../CustomComponents/TableComponent";
import { getEventList, resetSuccessFlag, updateEventStatus, getEventById } from "../../store/reducer/eventSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SearchComponent from "../CustomComponents/SearchComponent";
import SwalComponent from "../CustomComponents/SwalComponent";
import { toast } from "react-toastify";


const Events = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [selectedEvent, setSelectedEvent] = useState(null);

   const { eventListData, eventStatusSuccess } = useSelector((state) => state.events);
   const { count: totalCount, data: tableData } = eventListData;
   useEffect(() => {
      getData();
   }, []);

   function getData(payload = {}) {
      dispatch(getEventList(payload));
   }
   useEffect(() => {
      if (eventStatusSuccess) {
         toast.success("Event status updated successfully");
         getData();
         dispatch(resetSuccessFlag({ flag: "eventStatusSuccess" }))
      }

   }, [eventStatusSuccess, dispatch])

   const columns = [
      {
         title: "Event Name",
         dataIndex: "eventname",
         key: "event_name",
         align: "left",
         sorter: true,
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: record => (
            <SwalComponent
               message="Are you sure you want to change the Event status?"
               onClickDispatch={updateEventStatus}
               id={record.id}
               status={true}
               statusValue={record.status}
               postData={{ status: record.status == 1 ? 0 : 1 }}
            />
         ),
         sorter: (a, b) => a.status - b.status,

      },
      {
         title: "Action",
         key: "Action",
         align: "center",
         render: (data) => (
            <Space>
               <Tooltip title="Edit">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<EditOutlined
                        onClick={() => navigate(`/events/edit/${btoa(data?.id)}`)}
                        style={{ color: "blue" }} />}
                  />
               </Tooltip>
               <Tooltip title="View">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<EyeOutlined style={{ color: "green" }} />}
                     onClick={() => showModal(data)} // Trigger modal with event data
                  />
               </Tooltip>
               <Tooltip title="Copy">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<CopyOutlined style={{ color: "green" }} />}
                     onClick={() => navigate(`/events/edit/${btoa(data?.id)}?type=copy`)}// Trigger modal with event data
                  />
               </Tooltip>
            </Space>
         ),
      },
   ];
   const fieldTypeOptions = [
      { value: 1, label: "String" },
      { value: 2, label: "Number" },
      { value: 3, label: "List" },
      { value: 4, label: "Date" },
      { value: 5, label: 'Time' },
      { value: 6, label: 'Calculation' },
      { value: 7, label: 'Date&Time' },

   ];
   const validationOptions = [
      { value: 1, label: "numeric length" },
      { value: 2, label: "decimal" },
      { value: 3, label: "greater than" },
      { value: 4, label: "less than" },
     
      { value: 6, label: "string length" },
      { value: 7, label: "lowercase" },
      { value: 8, label: "uppercase" },
      { value: 9, label: "alphabetic" },
      { value: 10, label: "alphanumeric" },
      { value: 11, label: "format" },
      { value: 12, label: "Past Dates Allowed" },

      { value: 13, label: "Time format" },
      { value: 14, label: "less than equal" },
      { value: 15, label: "greater than equal" },
      { value: 16, label: "format" }

   ]
   const showModal = (data) => {
      dispatch(getEventById({ id: data.id }))
         .unwrap()
         .then((response) => {
            if (response?.event) {
               const event = response.event;

               // Format fields
               const formattedFields = event?.fields?.map((field) => {
                  // Map field type label
                  const fieldTypeLabel = fieldTypeOptions.find(
                     option => option.value === field.fieldtype
                  )?.label || 'Nil';

                  // Map validations for each field
                  const fieldValidations = event?.fields_validation
                     ?.filter(validation => validation.field_id === field.id)
                     ?.map(validation => {
                        // Map validation label
                        const validationLabel = validationOptions.find(
                           option => option.value === validation.validation
                        )?.label || 'Nil';

                        return {
                           validation: validationLabel, // Use label instead of ID
                           validationvalue: validation.validation === 5
                              ? validation.validationvalue.split('-') // Split range if required
                              : validation.validationvalue,
                           min: validation.minvalue,
                           max: validation.maxvalue,
                        };
                     });

                  return {
                     ...field,
                     fieldtype: fieldTypeLabel,
                     validationvalue: fieldValidations, // Add validations
                  };
               });

               // Update modal state
               setSelectedEvent({ ...event, fields: formattedFields });
               setIsModalVisible(true);
            }
         })
         .catch(() => {
            toast.error("Error fetching event details.");
         });
   };




   // Close modal
   const handleCancel = () => {
      setIsModalVisible(false);
   };

   return (
      <div>
         <PageHeader HeaderText="Events" Breadcrumb={[{ name: "Events" }]} backButtonUrl="" />
         <Card
            title="Events List"
            extra={
               <>
                  <Space size={"middle"} className="my-3">

                     <SearchComponent
                        placeHolder="Search Event Name"
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }} />


                  </Space>
                  <Link to="/events/add">
                     <Button className="primary-btn ml-2" shape="round">
                        Add
                     </Button>
                  </Link>
               </>
            }
         >
            <Flex justify="start">
               <div className="w-75">
                  <TableComponent columns={columns} dataSource={tableData} size={"small"} />
               </div>
            </Flex>

         </Card>

         {/* Modal to show event details */}
         <Modal
            title="Event Details"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
         >
            {selectedEvent && (
               <div style={{ padding: '20px' }}>
                  <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
                     Event Name: <span style={{ fontWeight: 'normal' }}>{selectedEvent.eventname}</span>
                  </p>
                  <p style={{ fontSize: '16px', marginBottom: '20px' }}>
                     Status: <span style={{ color: selectedEvent.status === 1 ? 'green' : 'red' }}>
                        {selectedEvent.status === 1 ? "Active" : "Inactive"}
                     </span>
                  </p>

                  {/* Display Fields */}
                  {selectedEvent.fields && selectedEvent.fields.length > 0 && (
                     <div>
                        <h4 style={{ marginBottom: '15px' }}>Fields:</h4>
                        <ul style={{ listStyle: 'none', paddingLeft: '0' }}>
                           {selectedEvent.fields.map((field, index) => (
                              <li
                                 key={index}
                                 style={{
                                    padding: '10px',
                                    marginBottom: '10px',
                                    border: '1px solid #ddd',
                                    borderRadius: '5px',
                                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                                 }}
                              >
                                 <p style={{ margin: '5px 0' }}><strong>Field Name:</strong> {field.fieldname}</p>
                                 <p style={{ margin: '5px 0' }}><strong>Field Type:</strong> {field.fieldtype}</p>

                                 {/* Field Validations */}
                                 {field.validationvalue && field.validationvalue.length > 0 && (
                                    <div style={{ marginTop: '10px' }}>
                                       <h5 style={{ marginBottom: '10px' }}>Validations:</h5>
                                       <ul style={{ listStyle: 'none', paddingLeft: '0' }}>
                                          {field.validationvalue.map((validation, vIndex) => (
                                             <li
                                                key={vIndex}
                                                style={{
                                                   padding: '5px',
                                                   backgroundColor: '#f9f9f9',
                                                   borderRadius: '3px',
                                                   marginBottom: '5px'
                                                }}
                                             >
                                                <p style={{ margin: '5px 0' }}>
                                                   <strong>Condition:</strong>
                                                   {fieldTypeOptions.values[vIndex] !== 3 && ( // Correct conditional rendering
                                                      <>
                                                         {validation.validation}
                                                      </>
                                                   )}
                                                </p>
                                                <p style={{ margin: '5px 0' }}>
                                                   <strong>Value:</strong> {Array.isArray(validation.validationvalue)
                                                      ? validation.validationvalue.join(' - ')
                                                      : validation?.validationvalue}
                                                </p>
                                                
                                                {validation.min && (
                  <p style={{ margin: '5px 0' }}>
                     <strong>Min:</strong> {validation.min}
                  </p>
               )}
               {validation.max && (
                  <p style={{ margin: '5px 0' }}>
                     <strong>Max:</strong> {validation.max}
                  </p>
               )}


                                             </li>
                                          ))}
                                       </ul>
                                    </div>
                                 )}
                              </li>
                           ))}
                        </ul>
                     </div>
                  )}
               </div>
            )}
         </Modal>


      </div>
   );
};

export default Events;
