import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Row, Pagination, Typography, Space, Table, Collapse, Flex } from "antd";
import { DeleteOutlined, FileDoneOutlined, HolderOutlined, RedoOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CSS } from "@dnd-kit/utilities";
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { yupResolver } from "@hookform/resolvers/yup";
import PageHeader from "../../view/pageheader";
import SelectInput from "../CustomComponents/SelectInput";
import TextInput from "../CustomComponents/TextInput";
import { getJobsList } from "../../store/reducer/jobSlice";
import { getEventList } from "../../store/reducer/eventSlice";
import SearchComponent from "../CustomComponents/SearchComponent";
import { debounce } from "lodash";
import { addWorkFlow, getWorkflowById, updateWorkFlow, resetSuccessFlag,getSpecListINWorkFlow } from "../../store/reducer/workflowSlice"
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const { Panel } = Collapse;

const AddEditWorkFlow = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { jobListData } = useSelector((state) => state.jobs);
   const { eventListData } = useSelector((state) => state.events);
   const [jobData, setJobData] = useState([]);
   const [eventData, setEventData] = useState([]);
   const [selectedEvents, setSelectedEvents] = useState([]);
   const [highlightedEvents, setHighlightedEvents] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [pageSize, setPageSize] = useState(20);
   const [formVisible, setFormVisible] = useState(true);
   const [type, setType] = useState("add");
   const { addWorkFlowSuccess, addWorkFlowError, addWorkFlowErrorMessage, updateWorkFlowSuccess, updateWorkFlowError, updateWorkFlowErrorMessage,specListData,workflowStatusSuccess,workflowStatusError,workflowStatusErrorMessage } = useSelector((state) => state.workflow);
   const { workflow_id } = useParams()
   const [searchParams] = useSearchParams();
   const [showEvents, setShowEvents] = useState(false);
   const copyQuery = searchParams.get("type");
   const statusOptions = [{
      label: "Active",
      value: 1,
   },
   {
      label: "Inactive",
      value: 0,
   },
   ];
   

   useEffect((payload = {}) => {
      dispatch(getSpecListINWorkFlow(payload));
      dispatch(getEventList(payload));
   }, [dispatch]);

   useEffect(() => {
      if (specListData?.data) {
         setJobData(specListData?.data);
      }
      if (eventListData?.data) {
         setEventData(eventListData.data);
      }
   }, [jobListData, eventListData]);

   useEffect(() => {
      if (copyQuery) {
         setType("copy");
         const id = atob(workflow_id);
         dispatch(getWorkflowById({ id }))
            .unwrap()
            .then((data) => {
               // Transform workflow events to match table format
               const formattedEvents = data?.data?.workflow_events.map(event => ({
                  id: event?.event?.id,              // ID of the event
                  eventname: event?.event?.eventname // Name of the event
               })) || [];

               // Reset form fields
               reset({
                  spec_id: data?.data?.spec_id,
                  workflow_name: "",
                  status: data?.data?.status,
                  event_id: formattedEvents.map(event => event?.id),
               });

               // Set selected events for the table
               setSelectedEvents(formattedEvents); // Properly formatted for the table
            });

      }
      else if (workflow_id) {
         setType("edit");
         const id = atob(workflow_id);
         dispatch(getWorkflowById({ id }))
            .unwrap()
            .then((data) => {
               // Transform workflow events to match table format
               const formattedEvents = data?.data?.workflow_events.map(event => ({
                  id: event?.event?.id,              // ID of the event
                  eventname: event?.event?.eventname // Name of the event
               })) || [];


               // Reset form fields
               reset({
                  spec_id: data?.data?.spec?.id,
                  workflow_name: data?.data?.workflow_name,
                  status: data?.data?.status,
                  event_id: formattedEvents.map(event => event.id),
               });

               // Set selected events for the table
               setSelectedEvents(formattedEvents); // Properly formatted for the table
            });
      }
   }, [workflow_id]);

   const handlePageChange = (page, pageSize) => {
      setCurrentPage(page);
      setPageSize(pageSize);
   };

   const paginatedEvents = useMemo(() => {
      const startIndex = (currentPage - 1) * pageSize;
      return eventData.filter((data)=>data?.status==1).slice(startIndex, startIndex + pageSize);
   }, [eventData, currentPage, pageSize]);

   const handleSearch = debounce((query) => {
      const payloadData = { query };
      dispatch(getEventList(payloadData));
   }, 300);

   const spec_id = useMemo(() => {
      return jobData.map((item) => ({
         label: item?.spec_id,
         value: item?.id,
      }));
   }, [jobData]);
   const validationRules = yup.object().shape({
      spec_id: yup.number().required("Spec id is required"),
      workflow_name: yup.string().required("Workflow Name is required"),
      status: yup.number().required("Status is required"),
      event_id: yup.array().of(yup.number().required()).min(1, "At least one event is required"),
   });

   const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm({
      resolver: yupResolver(validationRules),
      mode: "onChange",
      defaultValues: {
         status: 1,
      },
   });

   const handleShowEvents = () => {
      setShowEvents(prev => !prev); // Step 2: Toggle the state to show/hide events
   };

   const handleReset = () => {
      reset(); // Reset form fields
      setSelectedEvents([]); // Clear table data
   };

   useEffect(() => {
      setValue("event_id", selectedEvents.map(event => event.id));
   }, [selectedEvents, setValue]);
   useEffect(() => {
      if (addWorkFlowSuccess) {
         toast.success("Workflow added successfully");
         dispatch(resetSuccessFlag({ flag: "addWorkflowSuccess" }));
         navigate('/workflow')
      }
      if (addWorkFlowError) {
         toast.error(addWorkFlowErrorMessage);
         dispatch(resetSuccessFlag({flag:"addWorkFlowError"}));
      }
      if (updateWorkFlowSuccess) {
         toast.success("Workflow Updated successfully");
         dispatch(resetSuccessFlag({ flag: "updateWorkFlowSuccess" }));
         navigate('/workflow')
      }
      if (updateWorkFlowError) {
         toast.error(updateWorkFlowErrorMessage)
         dispatch(resetSuccessFlag({ flag: "updateWorkFlowError" }));

      }
      if (workflowStatusSuccess) {
         toast.success("Workflow status updated successfully");
         dispatch(resetSuccessFlag({ flag: "workflowStatusSuccess" }));
         navigate('/workflow')
         
      }
      if (workflowStatusError) {
         toast.error(workflowStatusErrorMessage);
         dispatch(resetSuccessFlag({ flag: "workflowStatusError" }));
      }
   }, [addWorkFlowSuccess, updateWorkFlowSuccess, addWorkFlowErrorMessage, addWorkFlowError, updateWorkFlowError, updateWorkFlowErrorMessage,workflowStatusError,workflowStatusSuccess,workflowStatusErrorMessage, navigate, dispatch])
   const handleEventSelect = (event) => {
      if (highlightedEvents.find((highlighted) => highlighted.id === event.id)) {
         setHighlightedEvents((prev) => prev.filter((e) => e.id !== event.id));
      } else {
         setHighlightedEvents((prev) => [...prev, event]);
      }
   };

   const handleAddSelectedEvents = () => {
      setSelectedEvents((prev) => [
         ...prev,
         ...highlightedEvents.filter(
            (highlighted) => !prev.find((selected) => selected.id === highlighted.id)
         ),
      ]);
      setHighlightedEvents([]);
   };

   const handleEventRemove = (eventId) => {
      Swal.fire({
         title: 'Are you sure?',
         text: "You want to remove this event?",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Yes, remove it!',
         cancelButtonText: 'No, cancel!',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            setSelectedEvents(prevEvents => prevEvents.filter(event => event.id !== eventId));
         return toast.success('Event Removed successfully');
         }
      });
   };

   const onSubmit = (data) => {
      if (type === "add" || type === "copy") {
         dispatch(addWorkFlow({ postData: data }));
      } else if (type === "edit") {
         const id = atob(workflow_id);
         dispatch(updateWorkFlow({ postData: data, updateID: id }));
      }
   };

   const SortableRow = (props) => {
      const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
         id: props["data-row-key"],
      });

      const style = {
         ...props.style,
         transform: CSS.Transform.toString(transform),
         transition,
         cursor: "move",
      };

      return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
   };

   const onDragEnd = ({ active, over }) => {
      if (active.id !== over?.id) {
         setSelectedEvents((prevState) => {
            const oldIndex = prevState.findIndex((item) => item?.id == active?.id);
            const newIndex = prevState.findIndex((item) => item?.id == over?.id);
            return arrayMove(prevState, oldIndex, newIndex);
         });
      }
   };

   const columns = [
      {
         key: "sort",
         align: "center",
         width: 80,
         render: () => <HolderOutlined style={{ cursor: "move", fontSize: "16px", color: "#1890ff" }} />,
      },
      {
         title: "Sl.No",
         dataIndex: "workflow_id",
         key: "workflow_id",
         align: "center",
         render: (text, record, index) => index + 1,
      },
      {
         title: "Event Name",
         dataIndex: "eventname",
         key: "eventname",
         align: "center",
      },
      {
         title: "Action",
         key: "Action",
         align: "center",
         render: (data) => (
            <Space>
               <Button type="primary" danger icon={<DeleteOutlined />} onMouseDown={(e) => {
                  e.preventDefault();
                  handleEventRemove(data.id);
               }}>
                  Delete
               </Button>
            </Space>
         ),
      },
   ];

   return (
      <div>
         <PageHeader HeaderText="WorkFlow" Breadcrumb={[{ name: "WorkFlow" }]} backButtonUrl="/workflow" />
         <Collapse defaultActiveKey={["1"]} onChange={() => setFormVisible(!formVisible)}>
            <Panel header="Workflow " key="1">
               {formVisible && (
                  <Card>
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={[10, 0]} wrap className="p-2">
                           <Col span={8}>
                              <SelectInput name="spec_id" control={control} label="Spec ID" placeholder="Select Spec_id" options={spec_id} errors={errors} required={true} />
                           </Col>
                           <Col span={8}>
                              <TextInput name="workflow_name" control={control} label="Work Flow Name" placeholder="Enter Workflow Name" required={true}  errors={errors} />
                           </Col>
                           <Col span={8}>
                              <SelectInput name="status" control={control} label="Status" placeholder="Select Status" defaultValue={1} options={statusOptions} errors={errors} required={true} />
                           </Col>
                        </Row>
                        <Button
                           className="primary-btn"
                           onClick={handleShowEvents}
                           style={{ marginBottom: '20px' }}
                        >
                           {!showEvents ? "Show Events" : 'Hide Events'}
                        </Button>

                        {showEvents && (
                           <>
                              <Row gutter={[10, 10]} justify="end" className="p-2">
                                 <Col span={8}>
                                    <SearchComponent placeHolder="Search Event..." onChange={(e) => handleSearch(e.target.value)} />
                                 </Col>
                                 {paginatedEvents.length > 0 && (
                                    <Row justify="center">
                                       <Pagination current={currentPage} pageSize={pageSize} total={eventData.length} onChange={handlePageChange} />
                                    </Row>
                                 )}
                              </Row>

                              <Row justify="start" gutter={[10, 10]} className="p-2">
  {paginatedEvents.length > 0 ? (
     paginatedEvents.map((event) => {
        // Check if the event name already exists in selectedEvents
        const isDuplicate = selectedEvents.some(selected => selected.eventname === event.eventname);
        const isHighlighted = highlightedEvents.find((highlighted) => highlighted.id === event.id);

        return (
           <Col span={6} key={event.id}>
              <Card 
                 bordered={true} 
                 onClick={() => handleEventSelect(event)} 
                 style={{
                    backgroundColor: isDuplicate 
                      ? "#ffcccc" // Light red for duplicate events
                      : isHighlighted 
                      ? "#e6f7ff" // Light blue for highlighted events
                      : "white", // Default white
                    fontWeight: 'bold', 
                    height: '80px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    transition: 'box-shadow 0.3s ease-in-out',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',   
                    maxWidth: '200px'
                 }}>
                 {event?.eventname}
              </Card>
           </Col>
        );
     })
  ) : (
     <Col span={24}>
        <Typography.Text>No data found</Typography.Text>
     </Col>
  )}
</Row>


                              <Flex gap="small" justify="center">
                                 <Button shape="round" className="primary-btn" onClick={handleAddSelectedEvents}>
                                    {"OK"}
                                 </Button>
                              </Flex>

                           </>
                        )}
                     </form>
                  </Card>
               )}
            </Panel>
         </Collapse>

         <DndContext collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext items={selectedEvents.map((item) => item?.id)} strategy={verticalListSortingStrategy}>
               <Table style={{ marginBottom: "30px" }} rowKey="id" components={{ body: { row: SortableRow } }} columns={columns} dataSource={selectedEvents} pagination={false} />
            </SortableContext>
         </DndContext>
         <Row justify="center" >
            <Space>
               <Button
               style={{ marginBottom: "30px" }}
                  className="primary-btn"
                  type="primary"
                  htmlType="submit"
                  icon={<FileDoneOutlined />}
                  onClick={handleSubmit(onSubmit)} // Trigger form submission on Save
               >
                  {type == "edit"?"Update":"Save"}
               </Button>
               
               <Button  style={{ marginBottom: "30px" }} onClick={handleReset} icon={<RedoOutlined />}>Reset</Button>
            </Space>
         </Row>
      </div>
   );
};

export default AddEditWorkFlow;
