import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, Col, Row, Button, Space, Modal, Radio, Input } from "antd";
import * as yup from "yup";
import { FileDoneOutlined, RedoOutlined, PlusOutlined, MinusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import PageHeader from "../../view/pageheader";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSuccessFlag, EventValidatByName } from "../../store/reducer/eventValidationSlice";
import TextInput from "../CustomComponents/TextInput";
import SelectInput from "../CustomComponents/SelectInput";
import { addEvent, getEventById, updateEvent } from "../../store/reducer/eventSlice";
import { useSearchParams } from "react-router-dom";


const AddEditEvents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { event_id } = useParams();
  const [searchParams] = useSearchParams();
  const copyQuery = searchParams.get("type");


  const [eventSchema] = useState({
    fieldtype: "",
    fieldname: "",
    status: 1,

  })

  const [isAddModal, setIsAddModal] = useState(false);
  const [type, setType] = useState("add");
  const [validationOptions, setValidationOptions] = useState([]);
  const [activeFieldIndex, setActiveFieldIndex] = useState(null);
  const [eventData, setEventData] = useState({});
  const { addEventSuccess, eventListByIdData, updateEventSuccess, addEventError, addEventErrorMessage } = useSelector((state) => state.events);
  const [selectedValidationValues, setSelectedValidationValues] = useState({});
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicateIndices, setDuplicateIndices] = useState([]);
  const [modalInputField, setMOdalInputFields] = useState([{

    operator: "",
    secondvalue: ""
  }])
  // Field Type Options
  const fieldTypeOptions = [
    { value: 1, label: "String" },
    { value: 2, label: "Number" },
    { value: 3, label: "List" },
    { value: 4, label: "Date" },
    { value: 5, label: 'Time' },
    { value: 6, label: 'Calculation' },
    { value: 7, label: 'Date & Time ' }
  ];
  const timeOptions = [
    { value: 'hh::mm::ss', label: 'hh::mm::ss' },
    { value: 'hh::mm', label: 'hh::mm' },
  ]
  const dateOptions = [
    { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
    { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' }
  ]
  const passedDateOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]
  const dateTimeOptions = [
    { value: 'DD/MM/YYYY hh:mm:ss', label: 'DD/MM/YYYY hh:mm:ss' },
    { value: 'DD/MM/YYYY hh:mm', label: 'DD/MM/YYYY hh:mm' },
    { value: 'MM/DD/YYYY hh:mm:ss', label: 'MM/DD/YYYY hh:mm:ss' },
    { value: 'MM/DD/YYYY hh:mm ', label: 'MM/DD/YYYY hh:mm ' }
  ]
  // Validation schema for the main form
  const validationRules = yup.object().shape({
    eventname: yup.string().trim().required("Event Name is required"),
    fieldvalue: yup.array().of(
      yup.object().shape({
        fieldname: yup.string().required("Field Name is required"),
        fieldtype: yup.number().required("Field Type is required")
      })

    ),
    status: yup.number().required("Status is required")
  });



  useEffect(() => {
    if (addEventSuccess) {
      toast.success("Event added successfully");
      dispatch(resetSuccessFlag({ flag: "addEventSuccess" }));
      navigate('/events');
    }
    if (updateEventSuccess) {
      toast.success("Event Updated successfully");
      dispatch(resetSuccessFlag({ flag: "updateEventSuccess" }));
      navigate('/events');

    }
    if (addEventError) {
      toast.error(addEventErrorMessage)

    }

  }, [addEventSuccess, updateEventSuccess, addEventError, addEventErrorMessage, navigate, dispatch]);

  useEffect(() => {
    if (eventListByIdData) {
      setEventData(eventListByIdData?.event);
    }
  }, [eventListByIdData]);
  // Validation schema for the modal form
  const modalValidationRules = yup.object().shape({
    modalFields: yup.array().of(
      yup.object().shape({
        validation: yup.number().notRequired("Validation type is required"),
        validationvalue: yup.string().notRequired("Validation value is required"),
        firstvalue: yup.string().notRequired(),
        operator: yup.string().notRequired(),
        secondvalue: yup.string().notRequired(),
        minvalue: yup.string().notRequired(),
        maxvalue: yup.string().notRequired(),

      })
    )
  });

  // Main form setup
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationRules),
    defaultValues: {
      eventname: "",
      fieldvalue: [],
      status: 1
    },
  });


  useEffect(() => {
    if (copyQuery == "copy") {
      const editId = atob(event_id); // Decode the event ID
      setType('copy');

      dispatch(getEventById({ id: editId }))
        .unwrap()
        .then((response) => {
          if (response?.event) {
            const event = response.event;


            // Map fields from the response to the format expected by the form
            const formattedFields = event?.fields?.map((field) => {
              // Filter validations for the current field based on field_id
              const fieldValidations = event?.fields_validation?.filter(
                (validation) => validation.field_id === field.id
              ).map((validation) => ({
                validation: validation?.validation,
                validationvalue: validation?.validationvalue,
                minvalue: validation?.minvalue,

                maxvalue: validation?.maxvalue
              }));


              return {
                fieldname: field?.fieldname,
                fieldtype: field?.fieldtype,
                field_id: field?.id,
                validationvalue: fieldValidations,
                minvalue: fieldValidations,
                maxvalue: fieldValidations
              };
            });
            reset({
              eventname: " ",
              fieldvalue: formattedFields,
              status: event.status || 1,
            });
          }
        })
        .catch((error) => {

          toast.error("Error fetching event details.");
        });
    }
    else if (event_id) {
      const editId = atob(event_id);

      setType('edit');

      dispatch(getEventById({ id: editId }))
        .unwrap()
        .then((response) => {
          if (response?.event) {
            const event = response.event;
            const formattedFields = event?.fields?.map((field) => {
              // Filter validations for the current field based on field_id
              const fieldValidations = event?.fields_validation?.filter(
                (validation) => validation.field_id === field.id


              ).map((validation) => ({


                validation: validation?.validation,

                validationvalue: validation?.validationvalue,
                minvalue: validation?.minvalue,

                maxvalue: validation?.maxvalue

              }));
              return {
                fieldname: field?.fieldname,
                fieldtype: field?.fieldtype,
                field_id: field?.id, // Include field_id to use later for updating validations
                validationvalue: fieldValidations, // Include validations for the field
                minvalue: fieldValidations,
                maxvalue: fieldValidations


              };
            });
            reset({
              eventname: event.eventname,
              fieldvalue: formattedFields,
              status: event.status || 1, // Default to active if status is missing
            });
          }
        })
        .catch((error) => {

          toast.error("Error fetching event details.");
        });
    }


  }, [copyQuery, event_id, dispatch, reset]);

  const watchedFieldValues = useWatch({
    control,
    name: "fieldvalue", // Watch the entire array of dynamic fields
  });
  useEffect(() => {
    if (activeFieldIndex !== null && watchedFieldValues) {
      const currentFieldType = watchedFieldValues[activeFieldIndex]?.fieldtype;
      if (currentFieldType !== undefined) {
        dispatch(EventValidatByName({ editId: [currentFieldType] }))
          .unwrap()
          .then((response) => {
            if (response) {
              const options = response.map((item) => ({
                label: item?.condition_name,
                value: item?.id,
              }));
              setValidationOptions(options);
            } else {
              // toast.error("Failed to fetch validation options");
            }
          })
          .catch(() => {
            // Handle error appropriately
            // toast.error("Error fetching validation options");
          });
      }
    }
  }, [activeFieldIndex, watchedFieldValues, dispatch]);

  const isFieldValid = (index) => {
    const field = watchedFieldValues?.[index];
    return field?.fieldname && field?.fieldtype;
  };

  // Function to handle removing an input field

  // Modal form setup
  const modalForm = useForm({
    resolver: yupResolver(modalValidationRules),
    defaultValues: {
      modalFields: [{
        validation: null,
        validationvalue: "",
        firstvalue: "",
        operator: "",
        secondvalue: "",
        minvalue: "",
        maxvalue: ""


      }],
      modalInputField: [{
        validationvalue: "",
        firstvalue: "",
        operator: "",
        secondvalue: "",

      }]
    },
  });
  const watchValidationTypes = modalForm.watch("modalFields");
  useEffect(() => {
    const subscription = modalForm.watch((value, { name, type }) => {
      if (name && name.includes('validation') && !name.includes('validationvalue')) {
        const fieldIndex = parseInt(name.match(/\d+/)[0]);
       
        
        // Reset the corresponding validation value fields
        modalForm.setValue(`modalFields.${fieldIndex}.validationvalue`, "");
        modalForm.setValue(`modalFields.${fieldIndex}.minvalue`, "");
        modalForm.setValue(`modalFields.${fieldIndex}.maxvalue`, "");
        
        // Clear any errors related to these fields
        modalForm.clearErrors([
          `modalFields.${fieldIndex}.validationvalue`,
          `modalFields.${fieldIndex}.minvalue`,
          `modalFields.${fieldIndex}.maxvalue`
        ]);
      }
    });

    // return () => subscription.unsubscribe();
  }, [modalForm]);

  // UseFieldArray for modal dynamic fields
  const {
    fields: modalFields,
    append: appendModalField,
    remove: removeModalField
  } = useFieldArray({
    control: modalForm.control,
    name: "modalFields",
  });

  // UseFieldArray for dynamic fields
  const {
    fields: dynamicFields,
    append: appendField,
    remove: removeField,
    update: updateField
  } = useFieldArray({
    control,
    name: "fieldvalue",
  });
  useEffect(() => {
    if (watchedFieldValues) {
      watchedFieldValues.forEach((field, index) => {
        const currentField = dynamicFields[index];
        if (currentField && 
            currentField.fieldtype !== field.fieldtype && 
            currentField.validationvalue) {
          // Clear validations when field type changes
          const updatedField = {
            ...field,
            validationvalue: [], // Reset validations
            minvalue: '',
            maxvalue: ''
          };
          updateField(index, updatedField);
          
          // If modal is open and showing validations for this field, close it
          if (activeFieldIndex === index && isAddModal) {
            onModalClose();
          }
        }
      });
    }
  }, [watchedFieldValues, dynamicFields, updateField, activeFieldIndex, isAddModal]);
  // Handle modal submission
  const onModalSubmit = (modalData) => {
  if (duplicateIndices.length > 0)  {
      return toast.error("Duplicate indices detected")
      
    }
    if (activeFieldIndex !== null) {
      const currentField = watchedFieldValues[activeFieldIndex];

      // Get values from both modalFields and modalInputField
      const modalFieldValidations = modalData.modalFields.map((field) => ({
        validation: field.validation,
        validationvalue: field.validationvalue,

        minvalue: field.minvalue,
        maxvalue: field.maxvalue

      }));
      let calculationValues = [];
      if (watchedFieldValues[activeFieldIndex]?.fieldtype === 6) {
        calculationValues = modalData.modalInputField.map((field) => ({
          firstvalue: field.firstvalue,
          operator: field.operator,
          secondvalue: field.secondvalue
        }));
      }

      // Combine all validations into a single update
      const updatedField = {
        ...currentField,
        validationvalue: watchedFieldValues[activeFieldIndex]?.fieldtype === 6
          ? calculationValues
          : modalFieldValidations
      };
      updateField(activeFieldIndex, updatedField);
      onModalClose();
    }
  };


  const eventStatus = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" }
  ];

  // Open modal for adding validation

  const onModalOpen = (index) => {
    const currentField = dynamicFields[index];


    setActiveFieldIndex(index);
    modalForm.clearErrors();
    if (currentField.validationvalue && currentField.validationvalue.length > 0) {
      const existingValidations = currentField.validationvalue.map((val) => ({
        validation: val.validation,
        validationvalue: val.validationvalue,
        firstvalue: val.firstvalue,
        operator: val.operator,
        secondvalue: val.secondvalue,
        minvalue: val.minvalue,
        maxvalue: val.maxvalue

      }));

      modalForm.reset({
        modalFields: existingValidations,
        modalInputField: existingValidations
      });
    } else {
      modalForm.reset({
        modalFields: [{
          validation: null,
          validationvalue: "",
          minvalue: "",
          maxvalue: "",
          operator: "", // Initialize operator
          secondvalue: "" // Initialize secondvalue
        }],
      });
    }

    setIsAddModal(true);
  };
  // Close modal
  const onModalClose = () => {
    modalForm.reset();
    setIsAddModal(false);
  };
  const addNewInputField = () => {
    setMOdalInputFields([
      ...modalInputField,
      { operator: "", secondvalue: "" }, // Only include operator and secondvalue fields
    ]);
  };

  // Function to remove a specific field
  const removeInputField = (index) => {
    const updatedFields = [...modalInputField];
    updatedFields.splice(index, 1);
    setMOdalInputFields(updatedFields);
  };
  const handleValidationValueChange = (index, value, field_type) => {



    setSelectedValidationValues((prev) => ({
      ...prev,
      [index]: field_type,
    }));
  };


  const handleValidationValueChange1 = (index, value, fieldType) => {
    if (fieldType === 3) {
      const duplicates = modalFields.reduce((acc, field, idx) => {
        if (field.validationvalue === value && idx !== index) {
          acc.push(idx); // Add duplicate indices
        }
        return acc;
      }, []);
      setDuplicateIndices(duplicates);
    } else {
      setDuplicateIndices([]); // Clear duplicates for other field types
    }
  };

  const getSecondValueOptions = (index) => {
    const selectedValue = selectedValidationValues[0];
    return CalcdropDown.filter((field) => field.fieldtype === selectedValue);
  };

  const onSubmit = (data) => {

    if (type == "add") {
      dispatch(addEvent({ postData: data }))
    } else if (type == "edit") {
      const editId = atob(event_id)
      dispatch(updateEvent({ postData: data, updateID: editId }))

    } else if (type == "copy") {
      dispatch(addEvent({ postData: data }))

    }
  };
  const handleReset = () => {
    reset();
    modalForm.reset();
  };
  const CalcdropDown = watchedFieldValues
    ?.filter((field) => field.fieldtype !== 6 && field.fieldtype != 1 && field.fieldtype != 3) // Filter out fields with fieldtype === 6
    .map((field, index) => ({
      label: field.fieldname,
      value: field.fieldname,
      fieldtype: field.fieldtype,
    }));

  return (
    <>
      <PageHeader HeaderText="Events" Breadcrumb={[{ name: "Events" }]} backButtonUrl="/events" />
      <Card title={type == "add" || "copy" ? "Add Event" : "Edit Event"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[10, 10]}>
            {/* Event Name Input */}
            <Col span={16}>
              <TextInput
                name="eventname"
                control={control}
                label="Event Name"
                placeholder="Enter your event name"
                errors={errors}
                required={true}
              />
            </Col>
            <Col span={8}>
              <SelectInput
                name="status"
                control={control}
                label="Status"
                placeholder="Select Status"
                options={eventStatus}
                errors={errors}
                required={true}
              />
            </Col>

            {/* Dynamic Fields */}
            {dynamicFields.map((field, index) => (
              <React.Fragment key={field.id}>
                <Col span={8}>
                  <TextInput
                    name={`fieldvalue.${index}.fieldname`}
                    control={control}
                    label="Field Name"
                    placeholder="Enter Field Name"
                    errors={errors}
                    required={true}
                  />
                  {errors?.fieldvalue?.[index]?.fieldname && (
                    <p style={{color:'red'}} className="error-message">
                      {errors.fieldvalue[index].fieldname.message}
                    </p>
                  )}
                </Col>

                <Col span={8}>
                  <SelectInput
                    name={`fieldvalue.${index}.fieldtype`}
                    control={control}
                    label="Field Type"
                    placeholder="Select Field Type"
                    options={fieldTypeOptions}
                    errors={errors}
                    required={true}
                  />
                  {errors?.fieldvalue?.[index]?.fieldtype && (
                    <p style={{color:'red'}} className="error-message">
                      {errors.fieldvalue[index].fieldtype.message}
                    </p>
                  )}
                </Col>
                <Col span={4}>
                  {isFieldValid(index) && (
                    <Button
                      className="primary-btn ml-2"
                      style={{ marginTop: "25px" }}
                      shape="round"
                      onClick={() => onModalOpen(index)}
                    >
                      {field.validationvalue && field.validationvalue.length > 0
                        ? "Update Validation"
                        : "Add Validation"}
                    </Button>
                  )}
                </Col>


                {dynamicFields.length > 1 && (
                  <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button
                      style={{ marginBottom: '25px' }}
                      className="bg-danger text-white"
                      type="danger"
                      icon={<MinusCircleOutlined />}
                      onClick={() => removeField(index)}
                    >
                      Remove
                    </Button>
                  </Col>
                )}
              </React.Fragment>
            ))}
          </Row>

          {/* Add Field Button */}
          <Row justify="center" style={{ marginTop: '16px' }}>
            <Space>
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => appendField({
                  fieldname: '',
                  fieldtype: null,
                  validationvalue: [],
                  minvalue: '',
                  maxvalue: '',

                })}
              >
                Add Field
              </Button>
            </Space>
          </Row>
          {/* Form Action Buttons */}
          <Row justify="center" style={{ marginTop: '16px' }}>
            <Space>
              <Button
                className="primary-btn"
                type="primary"
                htmlType="submit"
                icon={<FileDoneOutlined />}
              >
                {type == "add" || "copy" ? "Save" : "Update"}
              </Button>
              {type == "add" && (
                <>
                  <Button
                    onClick={handleReset}
                    icon={<RedoOutlined />}
                  >
                    Reset
                  </Button>
                </>
              )}
            </Space>
          </Row>
        </form>

        {/* Validation Modal */}
        <Modal
          title="Add Validation"
          className="w-50"
          open={isAddModal} // This should control whether the modal is visible
          onOk={modalForm.handleSubmit(onModalSubmit)} // On submit, handle the form submission
          onCancel={onModalClose} // Close the modal
          width={600}
          okText="Add Validation"
          // Conditionally hide the OK button
          okButtonProps={{
            style: { display: duplicateIndices.length > 0 ? 'none' : 'inline-block' },
          }}
        >
          <form onSubmit={modalForm.handleSubmit(onModalSubmit)}>
            {/* Dynamically render input fields */}
            {modalInputField.map((field, index) => {
              const fieldType = watchedFieldValues[activeFieldIndex]?.fieldtype;

              // Check if fieldType is 6, and render accordingly
              const shouldRender = fieldType === 6;

              return (
                shouldRender && (
                  <Row gutter={16} key={index}>
                    {/* Validation Value Field (only for the first row) */}
                    {index === 0 && (
                      <Col span={6}>
                        <SelectInput
                          name={`modalInputField.${index}.firstvalue`}
                          label={`First Value`}
                          control={modalForm.control}
                          placeholder="Select Value"
                          options={CalcdropDown}
                          onChange={(value) => {
                            const selectedField = CalcdropDown.find(
                              (field) => field.value === value
                            );
                            if (selectedField) {
                              handleValidationValueChange(
                                index,
                                value,
                                selectedField.fieldtype
                              );
                            }
                          }}
                          errors={modalForm.formState.errors}
                          required={true}
                        />
                      </Col>
                    )}

                    {/* Operator Field */}
                    <Col span={6}>
                      <SelectInput
                        name={`modalInputField.${index}.operator`}
                        label={"Operations"}
                        control={modalForm.control}
                        placeholder="Select Operator"
                        options={[
                          { value: "+", label: "Sum (+)" },
                          { value: "-", label: "Subtract (-)" },
                          { value: "*", label: "Multiply (*)" },
                          { value: "/", label: "Divide (/)" },
                        ]}
                        errors={modalForm.formState.errors}
                        required={true}
                      />
                    </Col>

                    {/* Second Value Field */}
                    <Col span={6}>
                      <SelectInput
                        name={`modalInputField.${index}.secondvalue`}
                        control={modalForm.control}
                        label={"Second Value"}
                        placeholder="Select Value"
                        options={getSecondValueOptions(index)}
                        errors={modalForm.formState.errors}
                        required={true}
                      />
                    </Col>

                    {/* Remove Button */}
                    {modalInputField.length > 1 && (
                      <Col span={2} style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          type="danger"
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeInputField(index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    )}

                    {/* Add Input Field Button */}
                    {/* {selectedValidationValues[index] !== 4 && selectedValidationValues[index] !== 5 && (
                      
                    )} */}
                  </Row>
                )
              );
            })}
            {watchedFieldValues[activeFieldIndex]?.fieldtype == 6 && selectedValidationValues[0] !== 4 && selectedValidationValues[0] !== 5 && (
              <>
                <Row justify="center" style={{ marginTop: "30px" }}>
                  <Col>
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={addNewInputField}
                    >
                      Add Input Field
                    </Button>
                  </Col>
                </Row>
              </>
            )}


            {/* Dynamic Modal Fields */}
            {watchedFieldValues[activeFieldIndex]?.fieldtype !== 6 && (
              <>
                {modalFields.map((field, index) => (
                  <Row key={field.id} gutter={[10, 10]} style={{ marginBottom: '16px' }}>
                    {/* Conditional rendering for fieldtype !== 6 */}
                    <React.Fragment>
                      {watchedFieldValues[activeFieldIndex]?.fieldtype !== 3 && (
                        <Col span={10}>
                          <SelectInput
                            name={`modalFields.${index}.validation`}
                            control={modalForm.control}
                            label="Validation Type"
                            placeholder="Select Validation"
                            options={validationOptions}
                            errors={modalForm.formState.errors}
                            required
                          />
                        </Col>
                      )}

                      {/* Conditional Rendering for Validation Input */}
                      {(() => {
                        const validation = modalForm.watch(`modalFields.${index}.validation`);
                        if (validation === 13) {
                          return (
                            <Col span={10}>
                              <SelectInput
                                name={`modalFields.${index}.validationvalue`}
                                control={modalForm.control}
                                label="Validation Type"
                                placeholder="Select Time Format"
                                options={timeOptions}
                                errors={modalForm.formState.errors}
                                required
                              />
                            </Col>
                          );
                        } else if (validation === 12) {
                          return (
                            <Col span={10}>
                              <SelectInput
                                name={`modalFields.${index}.validationvalue`}
                                control={modalForm.control}
                                label="Validation Type"
                                placeholder="Select Date Format"
                                options={passedDateOptions}
                                errors={modalForm.formState.errors}
                                required
                              />
                            </Col>
                          );
                        } else if (validation === 11) {
                          return (
                            <Col span={10}>
                              <SelectInput
                                name={`modalFields.${index}.validationvalue`}
                                control={modalForm.control}
                                label="Validation Type"
                                placeholder="Select Date Format"
                                options={dateOptions}
                                errors={modalForm.formState.errors}
                                required
                              />
                            </Col>
                          );

                        }
                        else if (validation === 16) {
                          return (
                            <Col span={10}>
                              <SelectInput
                                name={`modalFields.${index}.validationvalue`}
                                control={modalForm.control}
                                label="Validation Type"
                                placeholder="Select Date Format"
                                options={dateTimeOptions}
                                errors={modalForm.formState.errors}
                                required
                              />
                            </Col>
                          );
                        }
                        else if (validation === 6 || validation === 1) {
                          return (
                            <>
                              <Col style={{ marginTop: '-20px', width: '126px' }} >
                                <TextInput
                                  name={`modalFields.${index}.minvalue`}

                                  control={modalForm.control}
                                  label="Minimum Value"
                                  placeholder="Enter Value"
                                  errors={modalForm.formState.errors}
                                  required
                                />

                              </Col>
                              <Col style={{ marginTop: '-20px', width: '126px' }}  >
                                <TextInput
                                  name={`modalFields.${index}.maxvalue`}
                                  control={modalForm.control}
                                  label="Maximum Value"
                                  placeholder="Enter Value"
                                  errors={modalForm.formState.errors}
                                  required
                                />


                              </Col>
                            </>
                          );
                        }
                        else if (validation !== 7 && validation !== 8 && validation !== 9 && validation !== 10 && watchedFieldValues[activeFieldIndex]?.fieldtype !== 3) {
                          return (
                            <Col span={10}>
                              <TextInput
                                name={`modalFields.${index}.validationvalue`}
                                control={modalForm.control}
                                label="Validation Value"
                                placeholder="Enter Value"
                                errors={modalForm.formState.errors}
                                required
                              />
                              {isDuplicate && (
                                <span style={{ color: 'red', fontSize: '12px' }}>Duplicate value detected</span>
                              )}
                            </Col>
                          );
                        }
                        else if (watchedFieldValues[activeFieldIndex]?.fieldtype == 3) {
                          return (
                            <Col span={10}>
                              <TextInput
                                name={`modalFields.${index}.validationvalue`} // Correcting the name syntax
                                control={modalForm.control}
                                label="Validation Value"
                                placeholder="Enter Value"
                                errors={modalForm.formState.errors}
                                required
                                onChange={(e) => handleValidationValueChange1(index, e.target.value, watchedFieldValues[activeFieldIndex]?.fieldtype)}
                              />
                              {/* Show duplicate error if isDuplicate is true */}
                              {duplicateIndices.includes(index) && (
                                <span style={{ color: 'red', fontSize: '12px' }}>
                                  Duplicate value detected
                                </span>
                              )}
                            </Col>
                          );

                        }
                      })()}
                    </React.Fragment>

                    {/* Remove Field Button */}
                    {modalFields.length > 1 && watchedFieldValues[activeFieldIndex]?.fieldtype !== 6 && (
                      <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Button
                          style={{ marginBottom: '25px' }}
                          className="bg-danger text-white"
                          type="danger"
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeModalField(index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    )}
                  </Row>
                ))}
              </>
            )}

            {/* Add Modal Field Button */}
            {watchedFieldValues[activeFieldIndex]?.fieldtype !== 6 && watchedFieldValues[activeFieldIndex]?.fieldtype !== 7 && (
              <Row justify="center" style={{ marginTop: '16px' }}>
                <Space>
                  <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      appendModalField({
                        validation: null,
                        validationvalue: '',
                      })
                    }
                  >
                    Add Validation Field
                  </Button>
                </Space>
              </Row>
            )}
          </form>
        </Modal>
        {/* Form Submission Button */}

      </Card>
    </>
  );
};
export default AddEditEvents;