import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    customerList,
    customerDelete,
    createCustomer,
    editCustomer,
    customerStatusUpdate

} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "customers";

// Async thunk for fetching the Portal customer list
export const getCustomerList = createAsyncThunk(
    `${namespace}/getCustomerList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await customerList(query, page, limit, sortby, order);
console.log(data?.data?.datas, "data");

            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Portal customer
export const addCustomer = createAsyncThunk(
    `${namespace}/addCustomer`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
            console.log(payload, "payload");

            const data = await createCustomer({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Portal Customer data
export const updateCustomer = createAsyncThunk(
    `${namespace}/updateCustomer`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        console.log(updateID, "Updating User Group");

        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
            console.log(payload, "payload");

            const data = await editCustomer({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for deleting a Portal User group
export const deleteCustomer = createAsyncThunk(
    `${namespace}/deleteUserGroup`,
    async ({ deleteID }, { rejectWithValue }) => {


        try {
            const data = await customerDelete(deleteID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

//update customer statrus
export const updateCustomerStatus = createAsyncThunk(
    `${namespace}/updateCustomerStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await customerStatusUpdate({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// User Group Slice
const customerSlice = createSlice({
    name: namespace,
    initialState: {
        customerLoading: false,
        customerListData: [], // Ensure this structure matches the expected format
        customerListError: false,
        addCustomerSuccess: false,
        addCustomerError: false,
        addCustomerErrorMessage: "",
        updateCustomerSuccess: false,
        updateCustomerError: false,
        updateCustomerErrorMessage: "",

        deleteCustomerSuccess: false,
        deleteCustomerError: false,
        deleteCustomerErrorMessage: "",
        custometerStatusSuccess: false,
        custometerStatusError: false,
        custometerStatusErrorMessage: "",
    },

    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },

    extraReducers: (builder) => {
        builder
            // Handle getUserGroupList
            .addCase(getCustomerList.pending, (state) => {
                state.customerLoading = true;
            })
            .addCase(getCustomerList.fulfilled, (state, action) => {
                const data = action.payload;

                state.customerListData = data;

                state.customerLoading = false;
            })

            .addCase(getCustomerList.rejected, (state) => {
                state.customerLoading = false;
            })

            // Handle addCustoer
            .addCase(addCustomer.pending, (state) => {
                state.customerLoading = true;
                state.addCustomerError = false;
                state.addCustomerSuccess = false;
            })
            .addCase(addCustomer.fulfilled, (state) => {
                state.customerLoading = false;
                state.addCustomerSuccess = true;


            })
            .addCase(addCustomer.rejected, (state, action) => {
                state.customerLoading = false;
                state.addCustomerError = true;
                state.addCustomerErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })

            // Handle updateUserGroup
            .addCase(updateCustomer.pending, (state) => {
                state.updateUserGroupSuccess = false;
                state.updateUserGroupError = false;
                state.updateCustomerErrorMessage = "";
            })
            .addCase(updateCustomer.fulfilled, (state) => {
                state.updateCustomerSuccess = true;
            })
            .addCase(updateCustomer.rejected, (state, action) => {
                state.updateCustomerError = true;
                state.updateCustomerErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })

            // Handle deleteUserGroup
            .addCase(deleteCustomer.pending, (state) => {
                state.customerLoading = true;
                state.deleteCustomerError = false;
                state.deleteCustomerErrorMessage = false;
            })
            .addCase(deleteCustomer.fulfilled, (state) => {
                state.customerLoading = false;
                state.deleteCustomerSuccess = true;
            })
            .addCase(deleteCustomer.rejected, (state, action) => {
                state.customerLoading = false;
                state.deleteCustomerError = true;
                state.deleteCustomerErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })

            // Handle updateUserGroupStatus
            .addCase(updateCustomerStatus.pending, (state) => {
                state.customerLoading = true;
                state.custometerStatusError = false;
                state.custometerStatusErrorMessage = false;
            })
            .addCase(updateCustomerStatus.fulfilled, (state) => {
                state.customerLoading = false;
                state.custometerStatusSuccess = true;
            })
            .addCase(updateCustomerStatus.rejected, (state, action) => {
                state.customerLoading = false;
                state.custometerStatusError = true;
                state.custometerStatusErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            });
    },
});

// Export actions and selectors
export const { resetSuccessFlag } = customerSlice.actions;
export const customerSelector = (state) => state[namespace];
export default customerSlice.reducer;
