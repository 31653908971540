import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Modal, Row, Space, Tooltip } from "antd";
import { useState, useRef, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import PageHeader from "../../view/pageheader";
import EmailInput from "../CustomComponents/EmailInput";
import SelectInput from "../CustomComponents/SelectInput";
import TableComponent from "../CustomComponents/TableComponent";
import TextInput from "../CustomComponents/TextInput";
import { useDispatch, useSelector } from "react-redux";
import SwalComponent from "../CustomComponents/SwalComponent";
import { getUserGroupList } from "../../store/reducer/userGroupSlice";
import { addUserManagement, getUserManagementList, resetSuccessFlag, updateUserManagement, deleteUserManagement, userUpdateStatus } from "../../store/reducer/userManagementSlice";
import { toast } from "react-toastify";
import SearchComponent from "../CustomComponents/SearchComponent";
const UserManagement = () => {
   const dispatch = useDispatch();
   const [userSchema] = useState({
      user_name: "",
      email: "",
      role_id: null,
      user_group: [],
      status: 1,
      created_by: "",
   });
   const role_id = localStorage.getItem("role_id");
   const [userRoles] = useState(() => {
      if (role_id == 1) {
         return [
            { label: "Admin", value: 1 },
            { label: "Project Manager", value: 2 },
            { label: "Production User", value: 3 },
            { label: "Sales Person", value: 4 }
         ];
      } else if (role_id == 2) {
         return [

            { label: "Production User", value: 3 },
         ];
      } else {
         return []; // Default value if none of the conditions match
      }
   });
   const [userStatus] = useState([
      { label: "Active", value: 1 },
      { label: "Inactive", value: 0 },
   ]);
   const user = JSON.parse(localStorage.getItem('user_id'));
   const validationRules = yup.object().shape({
      user_name: yup.string().trim().required("Username is required"),
      email: yup.string().email("Email should contain @ and .").trim().required("Email is required"),
      role_id: yup.number().typeError("Please select role").required("Role is required"),
      user_group: yup.lazy((_, { parent }) =>
         parent.role_id === 3
            ? yup.array().min(1, "At least one group is required")
            : yup.array().notRequired()
      ),
 status: yup.number().typeError("Please select status").required("Status is required")
   });
   const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
      reset,
   } = useForm({
      resolver: yupResolver(validationRules),
      defaultValues: {
         role_id: null, // Explicitly set the default value
      },
      mode: "onChange", // Enable live validation
   });
   var watchRoleIdField = useWatch({
      control,
      name: "role_id"
   });
   useEffect(() => {
   if (watchRoleIdField && watchRoleIdField !== 3) {
      setValue("user_group", []); // Reset user_group to an empty array
   }
}, [watchRoleIdField, setValue]);
   const [isAddModal, setIsAddModal] = useState(false);
   const [isEditMode, setIsEditMode] = useState(false);
   const [editData, setEditData] = useState(null); // Store current editing user data
   const [userGroups, setUserGroups] = useState([]);
   const [selectRole, setSelectRole] = useState(null);
   const [selectedRole, setSelectedRole] = useState(null); 
   const onChangeRole = (value) => {
      setSelectedRole(value); // Update selected role
      if (value !== 3) {
         setValue("user_group", []); // Reset user group if role is not Production User
      }
   };
   const inputAutoFocusRef = useRef(null);
   const {
      userGroupListData,
   } = useSelector((state) => state.userGroups);
   const {
      userManagementListData,
      addUserManagementSuccess,
      addUserManagementError,
      addUserManagementErrorMessage,
      updateUserManagementSuccess,
      updateUserManagementError,
      updateUserManagementErrorMessage,
      deleteUserManagementSuccess,
      userStatusSuccess
   } = useSelector((state) => state.userManagement);
   const { count: totalCount, data: tableData } = userManagementListData;
const localStorageRole = JSON.parse(localStorage.getItem("role_id"));
   function getDataUserGroup(payload = {}) {
      dispatch(getUserGroupList(payload));
   }
   useEffect(() => {
      getDataUserGroup();
   }, []);
   useEffect(() => {
      if (userGroupListData?.data) {
         setUserGroups(userGroupListData?.data);
      }
   }, [userGroupListData]);
   const userGroup = useMemo(() => {
      return userGroups.filter((item) => item?.status === 1).map((item) => ({
         label: item?.user_group_name,
         value: item?.id,
      }));
   }, [userGroups]);

   function getData(payload = {}) {
      dispatch(getUserManagementList(payload));
   }
   useEffect(() => {
      getData();
      if (addUserManagementSuccess) {
         toast.success("User  added successfully");
         setIsAddModal(false); // Close the modal
         reset({ ...userSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "addUserManagementSuccess" })); // Reset the success flag
      }
      if (addUserManagementError && addUserManagementErrorMessage) {
         toast.error(addUserManagementErrorMessage); // Display error message for adding
      }
      if (updateUserManagementSuccess) {
         toast.success("User  updated successfully");
         setIsAddModal(false); // Close the modal
         reset({ ...userSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "updateUserManagementSuccess" })); // Reset the success flag
      }
      if (updateUserManagementError && updateUserManagementErrorMessage) {
         toast.error(updateUserManagementErrorMessage); // Display error message for updating
      }
      if (deleteUserManagementSuccess) {
         toast.success("User  deleted successfully");
         reset({ ...userSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "deleteUserManagementSuccess" })); // Reset the success flag
      }
      if (userStatusSuccess) {
         toast.success("User status updated successfully");
         reset({ ...userSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "userStatusSuccess" })); // Reset the success flag
      }
   }, [
      addUserManagementSuccess,
      addUserManagementError,
      addUserManagementErrorMessage,
      updateUserManagementSuccess,
      updateUserManagementError,
      updateUserManagementErrorMessage,
      deleteUserManagementSuccess,
      dispatch,
      reset,
      userSchema,
      userStatusSuccess
   ]);

   useEffect(() => {
      if (isAddModal && inputAutoFocusRef.current) {
         setTimeout(() => {
            inputAutoFocusRef.current.focus();
         }, 300);
      }
   }, [isAddModal]);

   const columns = [

      {
         title: "Name",
         dataIndex: "user_name",
         key: "user_name",
         align: "center",
         sorter: (a, b) => a.user_name.localeCompare(b.user_name),
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
         align: "center",
         sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
         title: "Role",
         dataIndex: "role_id",
         key: "role_id",
         align: "center",
         render: (role_id) => {
            const roles = {
               1: { label: "Admin" },
               2: { label: "Project Manager", },
               3: { label: "Production User", },
               4: { label: "Sales Person", },
            };
            const defaultRole = { label: "Inactive", color: "red" };
            const { label, color } = roles[role_id] || defaultRole;
            return <span style={{ color }}>{label}</span>;
         },
         sorter: true,
      },

      {
         title: "Status",
         key: "status",
         align: "center",
         render: record => (
            <SwalComponent
               message="Are you sure you want to change the User status?"
               onClickDispatch={userUpdateStatus}
               id={record.id}
               status={true}
               statusValue={record.status}
               postData={{ status: record.status == 1 ? 0 : 1 }}
            />
         ),
         sorter: (a, b) => a.status - b.status,
      },
      {
         title: "Action",
         key: "action",
         align: "center",
         render: data => (
            <Space>
               <Tooltip title="Edit">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<EditOutlined style={{ color: "blue" }} />}
                     onClick={() => {
                        setIsEditMode(true);
                        setEditData(data);
                        const userGroupIds = data.user_groups?.map(group => group?.status==1?group?.group_id:group?.user_group_name);
                        reset({
                           user_group: userGroupIds, // Populate with existing user groups
                           ...data
                        });
                        setIsAddModal(true);
                     }}
                  />
               </Tooltip>

               <Tooltip title="Delete">

                  <SwalComponent
                     message="Are you sure you want to delete the user ?"
                     onClickDispatch={deleteUserManagement}
                     id={data.id}
                  />
               </Tooltip>
            </Space>
         ),
      },
   ];

   const onSubmit = (data) => {
      const created_by = Number(localStorage.getItem('user_id'))
      if (data.role_id == 3 && (!data.user_group || data.user_group === "")) {
         return toast.error("Please select user group");
      }
      if (isEditMode) {
         // Destructure editData to exclude the `usergroups` field
         const { user_groups, ...filteredEditData } = data;
         dispatch(updateUserManagement({
            postData: {
               ...filteredEditData,
               created_by
            },
            updateID: editData.id
         }));

      } else {
         const payload = {
            postData: {
               ...data,
               created_by
            },
         };
       dispatch(addUserManagement(payload));
      }
   };
const onModalClose = () => {
      reset();
      setIsAddModal(false);
      setIsEditMode(false);
   };
const onModalOpen = () => {
      reset({ ...userSchema });
      setIsAddModal(true);
      setIsEditMode(false);
   };

   return (
      <div>
         <PageHeader
            HeaderText="User Management"
            Breadcrumb={[{ name: "User Management" }]}
            backButtonUrl=""
         />
         <Card
            title="Users List"
            extra={
               <>
                  <Space size={"middle"} className="my-3">
                     <SearchComponent
                        placeHolder="Search..."
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }} />
                  </Space>
                  <Button className="primary-btn ml-2" shape="round" onClick={onModalOpen}>
                     Add
                  </Button>
               </>
            }
         >
            <TableComponent columns={columns} dataSource={localStorageRole == 1 ? tableData : tableData?.filter((item) => item?.role_id !== 1)} size={"small"} />
         </Card>

         <Modal
            title={isEditMode ? "Edit User" : "Add User"}
            centered
            className="w-50"
            open={isAddModal}
            onOk={handleSubmit(onSubmit)} // Correctly handle form submission
            onCancel={onModalClose}
            okButtonProps={{ className: "primary-btn", shape: "round" }}
            okText={isEditMode ? "Update" : "Save"}
            cancelButtonProps={{ shape: "round" }}
         >
            <form onSubmit={handleSubmit(onSubmit)}>
               <Row gutter={[10, 0]} wrap className="p-2">
                  <Col span={12}>
                     <TextInput
                        ref={inputAutoFocusRef}
                        name="user_name"
                        control={control}
                        label="Username"
                        placeholder="Enter username"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={12}>
                     <EmailInput
                        name="email"
                        control={control}
                        label="Email"
                        placeholder="Enter email"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  
                  {
                     isEditMode ? (
                        <Col span={12}>
                           <SelectInput
                              name="role_id"
                              control={control}
                              label="User Role"
                              placeholder="Select user role"
                              options={userRoles}
                              errors={errors}
                              required={true}
                              disabled={true} // Makes the input read-only
                           />
                        </Col>
                     ) : (
                        <Col span={12}>
                           <SelectInput
                              name="role_id"
                              control={control}
                              label="User Role"
                              placeholder="Select user role"
                              onChange={onChangeRole} // Role change handler for non-edit mode
                              options={userRoles}
                              errors={errors}
                              required={true}
                           />
                           
                        </Col>
                     )
                  }
                  {watchRoleIdField == 3 && (
                     <Col span={12}>
                        <SelectInput
                           name="user_group"
                           control={control}
                           label="User Group"
                           placeholder="Select user group"
                           options={userGroup}
                           errors={errors}
                           required={true}
                           mode="multiple" // Enable multi-select mode
                        />
                     </Col>
                  )}
                  <Col span={12}>
                     <SelectInput
                        name="status"
                        control={control}
                        label="Status"
                        placeholder="Select Status"
                        options={userStatus}
                        errors={errors}
                        required={true}
                     />
                  </Col>
               </Row>
            </form>
         </Modal>;
      </div>
   );
};

export default UserManagement;
