import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    createWorkflow,
    editWorkFlow,
    SpecListInWorkflow,
    workflowById,
    workflowList,
    workflowStatusUpdate,

} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "workflow";

export const addWorkFlow = createAsyncThunk(
    `${namespace}/addWorkFlow`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);

            const data = await createWorkflow({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getWorkFlowList = createAsyncThunk(
    `${namespace}/getWorkFlowList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", id = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await workflowList(query, page, limit, sortby, order, id);
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getSpecListINWorkFlow = createAsyncThunk(
    `${namespace}/getSpecListINWorkFlow`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", id = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await SpecListInWorkflow(query, page, limit, sortby, order, id);
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getWorkflowById = createAsyncThunk(
    `${namespace}/getWorkflowById`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await workflowById(id);

            return JSON.parse(EncryptDecrypt.decryptdata(data?.data?.datas, secretKey));
        } catch (error) {


            return rejectWithValue(error.response);
        }
    }
)

export const updateWorkFlow = createAsyncThunk(
    `${namespace}/updateWorkFlow`,
    async ({ postData, updateID }, { rejectWithValue }) => {
  try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editWorkFlow({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)

export const updateWorkFlowStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await workflowStatusUpdate({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const workflowSlice = createSlice({
    name: namespace,
    initialState: {
        workflowListData: [],
        specListData:[],
        workflowLoading: false,
        addWorkFlowSuccess: false,
        addWorkFlowError: false,
        addWorkFlowErrorMessage: "",
        workflowByIdData: [],
        workflowByIdError: false,
        updateWorkFlowSuccess: false,
        updateWorkFlowError: false,
        updateWorkFlowErrorMessage: "",
       workflowStatusSuccess: false,
       workflowStatusError: false,
       workflowStatusErrorMessage: "",

    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addWorkFlow.pending, (state) => {
                state.workflowLoading = true;
            })
            .addCase(addWorkFlow.fulfilled, (state) => {
                state.workflowLoading = false;
                state.addWorkFlowSuccess = true;
                state.addWorkFlowError = false;
            })
            .addCase(addWorkFlow.rejected, (state, action) => {
                state.addWorkFlowError = true;
                state.addWorkFlowErrorMessage =
                    action.payload.data.message || "An error occurred";
            })
            .addCase(getWorkFlowList.pending, (state) => {
                state.workflowLoading = true;
            })
            .addCase(getWorkFlowList.fulfilled, (state, action) => {
                const data = action.payload;
                state.addWorkFlowSuccess = false;

                state.workflowListData = data;
                state.workflowLoading = false;
            })
            .addCase(getWorkFlowList.rejected, (state) => {
                state.workflowLoading = false;
            })
            .addCase(getWorkflowById.pending, (state) => {
                state.workflowLoading = true;
            })
            .addCase(getWorkflowById.fulfilled, (state, action) => {
                state.workflowLoading = false;
                state.workflowByIdData = action.payload;
                state.workflowByIdError = false;
            })
            .addCase(getWorkflowById.rejected, (state) => {
                state.workflowByIdError = true;
            })
            .addCase(updateWorkFlow.pending, (state) => {
                state.workflowLoading = true;
            })
            .addCase(updateWorkFlow.fulfilled, (state)=>{
                state.workflowLoading = false;
                state.updateWorkFlowSuccess = true;
                state.updateWorkFlowError = false;
            })
            .addCase(updateWorkFlow.rejected, (state, action) => {
                state.updateWorkFlowError = true;
                console.log(action.payload.data.message,"action.payload.data.message");
                
                state.updateWorkFlowErrorMessage =
                    action.payload.data.message || "An error occurred";
            })
            .addCase(updateWorkFlowStatus.pending, (state) => {
                state.workflowLoading = true;
            })
            .addCase(updateWorkFlowStatus.fulfilled, (state) => {
                state.workflowLoading = false;
                state.workflowStatusSuccess = true;
                state.workflowStatusError = false;
            })
            .addCase(updateWorkFlowStatus.rejected, (state, action) => {
                state.workflowStatusError = true;
                state.workflowStatusErrorMessage =
                    action.payload.data.message || "An error occurred";
            })
            .addCase(getSpecListINWorkFlow.pending, (state) => {
                state.workflowLoading = true;
            })
            .addCase(getSpecListINWorkFlow.fulfilled, (state, action) => {
                const data = action.payload;
             
                
                state.workflowLoading = false;
                state.specListData = data;
            })
            .addCase(getSpecListINWorkFlow.rejected, (state) => {
                state.workflowLoading = false;
            });


    },
});

export const { resetSuccessFlag } = workflowSlice.actions;
export const workflowSelector = (state) => state[namespace];
export default workflowSlice.reducer;
