import React from "react";
import { Form, DatePicker } from "antd";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

const DateTimeInput = ({ name, control, label, placeholder, errors, required }) => (
   <Form.Item
      layout="vertical"
      label={
         <span>
            {label}
            {required && (
               <span className="ms-1" style={{ color: "red" }}>
                  *
               </span>
            )}
         </span>
      }
      validateStatus={errors[name] ? "error" : ""}
      help={errors[name]?.message}
   >
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <DatePicker
               {...field}
               className="w-100"
               placeholder={placeholder}
               format="MM-DD-YYYY hh:mm A" // Updated to 12-hour format with AM/PM
               showTime={{
                  format: "hh:mm A", // Time format with AM/PM
                  use12Hours: true,  // Enable 12-hour clock
               }}
               value={field.value ? dayjs(field.value) : null}
               onChange={(date, dateString) => field.onChange(date)}
            />
         )}
      />
   </Form.Item>
);

export default DateTimeInput;
