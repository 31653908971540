import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { Input, Form } from "antd";

const TextInput = forwardRef(({ name, control, label, placeholder, errors, required, onChange }, ref) => (
   <Form.Item
      layout="vertical"
      label={
         <span>
            {label}
            {required && (
               <span className="ms-1" style={{ color: "red" }}>
                  *
               </span>
            )}
         </span>
      }
      validateStatus={errors[name] ? "error" : ""}
      help={errors[name]?.message}
   >
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <Input
               {...field}
               ref={el => {
                  if (ref) ref.current = el;
                  field.ref(el);
               }}
               placeholder={placeholder}
               onChange={(e) => {
                  // If there is a parent onChange handler, call it
                  if (onChange) {
                     onChange(e);
                  }
                  // Always call field.onChange to update react-hook-form
                  field.onChange(e);
               }}
            />
         )}
      />
   </Form.Item>
));

export default TextInput;
